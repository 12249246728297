import './ImageFileCard.less'
import DoubleDotsMenu from '@/components/double-dots-menu/DoubleDotsMenu'
import { getImageSize } from '@/helpers/getImageSize'
import { sleep } from '@/helpers/sleeper'
import { getFileFromUrl } from '@/utilities/getFileFromUrl'
import { getUrlExtension } from '@/utilities/utilities'

import { Modal, Skeleton } from 'antd'
import classnames from 'classnames'
import downloadjs from 'downloadjs'
import React, { FC, MouseEventHandler, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Button from '../button/Button'

import { SvgIcon } from '../icon'
import Typography from '../typography/Typography'

const { Text, Paragraph } = Typography
type CardProps = {
  itemId?: InputMaybe<IdFilterInput>
  imageUrl?: string | null | undefined
  name: string
  size?: Maybe<number | string>
  isAdd?: boolean
  onDelete?: MouseEventHandler<HTMLElement>
  isBordered?: boolean
  ext?: Maybe<string>
  isShowRemoveButton?: boolean
  isShowMenuButton?: boolean
  onRemoveItem?: (id: InputMaybe<IdFilterInput>) => Promise<void>
}

const ImageFileCard: FC<CardProps> = ({
                                        ext,
  itemId,
  isShowRemoveButton = false,
                                        isShowMenuButton = false,
  onRemoveItem,
                                        isBordered = false, imageUrl, name, size, isAdd = false, onDelete }) => {
  const extension = (ext || imageUrl && getUrlExtension(imageUrl))
  const [visible, setVisible] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const onDownload = async () => {
    if (imageUrl) {
      const fileData = await getFileFromUrl(imageUrl)
      downloadjs(fileData, `${name}.${extension}`)
    }
  }
  const onImagePreview = async () => {
    setIsLoading(true)
    if (extension !== 'json') {
      setVisible(true)
      await sleep(500)
      setIsLoading(false)
    }
  }
  const handleCancel = () => setVisible(false)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  async function onDocumentLoadSuccess(props: { numPages: number }) {
    const { numPages } = props
    setPageNumber(numPages)
  }

  const cardStyles = classnames({
    'image-card': true,
    'bordered': isBordered,
  })
  const menuItems = [{ label: 'Download file', link: '', onClick: onDownload }]
  if (isShowRemoveButton && onRemoveItem) {
    menuItems.push({ label: 'Remove file', link: '', onClick: () => onRemoveItem(itemId) })
  }
  return (
    <div className={cardStyles}>
      <div className={'image-block'}>
        {imageUrl && (extension !== '.pdf' && extension !== '.json' && extension !== '.kml' && extension !== '.doc') ? (
          <div className={'image-wrapper'} onClick={onImagePreview}>
            <img src={imageUrl} alt={''} />
          </div>
        ) : (
          <div className={'image-wrapper'} onClick={onImagePreview}>
            <SvgIcon type={'doc'} />
          </div>
        )}
        <div className={'text-block'}>
          <Paragraph size={'md'}>{name}</Paragraph>
          {size && <Text color={'gray'}>{getImageSize(size)}</Text>}
        </div>
      </div>
      <div className={'icon-block'}>
        {/*<a href={imageUrl} download={'file-name.png'} target={'_blank'}>*/}
        <Button btnType={'icon'} icon={<SvgIcon type={'download'} onClick={onDownload} />} />
        {/*</a>*/}
        {isAdd && <Button btnType={'icon'} icon={<SvgIcon type={'clip'} />} />}
        {isShowRemoveButton && onRemoveItem && <Button btnType={'icon'} icon={<SvgIcon type={'bin'} />} onClick={()=>onRemoveItem(itemId)} />}
      </div>
      {isShowMenuButton && <DoubleDotsMenu menuItems={menuItems} />}

      <Modal
        width={800}
        open={visible}
        onCancel={handleCancel}
        title={''}
        footer={null}
        closeIcon={<SvgIcon type={'close-modal'} />}
        className={'preview-image-modal'}
      >
        {(extension === '.jpg' || extension === '.png' || extension === '.jpeg' || extension === '.webp') && (
          <div className={'preview-image-wrapper'}>
            <img src={imageUrl || ''} alt={'image'} style={{ width: '100%' }} />
          </div>
        )}
        {extension === '.pdf' && (
          <Document
            file={{ url: imageUrl || '' }}
            options={{ enableXfa: false, cMapPacked: false, disableStream: true, isEvalSupported: false }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={isLoading}
          >

            {isLoading ? <Skeleton loading paragraph={{ rows: 20 }} /> : Array.from({ length: pageNumber }, (_, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
        )}
      </Modal>
    </div>
  )
}
export default ImageFileCard
